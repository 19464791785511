@media screen and (max-width: 768px) {
  #about,
  #services,
  #team,
  #contact,
  #portfolio,
  #footer {
    padding: 36px 0;
  }
}
